// document.title = '川锁居家'
document.title = '金沙'
import Vue from 'vue'

import ElementUI from 'element-ui';
import '../theme/index.css'
import '../theme/iconfont.css'
import './basic/basic.css'
import './basic/basic.less'
import 'view-design/dist/styles/iview.css';
import App from './App.vue'
import router from './router'
import store from './store'
import Layout from './layout/index.vue'
import './router/permission'
import echarts from 'echarts'
import md5 from 'js-md5';
import axios from 'axios'
import './icons'
import htmlToPdf from "./components/htmlToPdf/htmlToPdf"
Vue.use(htmlToPdf)
import * as util from './util/util.js'
Vue.prototype.$util = util

import VueJsonp from 'vue-jsonp'
Vue.use(VueJsonp)

import plugins from './components/common/index.js'
Vue.use(plugins)

import storeplugins from './components/storeplugins/index.js'
Vue.use(storeplugins)

import retailplugins from './components/retailplugins/index.js'
Vue.use(retailplugins)

import _ from 'lodash'
Vue.prototype._ = _

import Meta from "vue-meta";
Vue.use(Meta);

import Print from 'vue-print-nb'
Vue.use(Print);

// import VueBus from 'vue-bus'
// Vue.use(VueBus);
Vue.prototype.$bus = new Vue({})

import {
	DatePicker
} from 'view-design';
import {
	resetRouter
} from './router/index.js'
// import tim from './util/tim.js'
// import TIM from 'tim-js-sdk/tim-js-friendship.js'
Vue.component('DatePicker', DatePicker);
// Vue.prototype.$tim = tim
// Vue.prototype.TIM = TIM
Vue.prototype.$echarts = echarts
Vue.prototype.$md5 = md5;
// Vue.prototype.$bus = new Vue() // event Bus 用于无关系组件间的通信。


Vue.use(ElementUI);

Vue.config.productionTip = false

let baseURL = ''

axios.defaults.baseURL = baseURL //域名:端口’;
// 拦截Axios发起的所有请求，给请求添加token
axios.interceptors.request.use(
	config => {
		const token = sessionStorage.getItem("token");
		if (token) {
			// 判断是否存在token，如果存在的话，则每个http header都加上token
			config.headers["Authorization"] = 'Bearer' + token;
		}
		if (sessionStorage.getItem("userinfo")) {
			const peripheryAdminUser = (JSON.parse(sessionStorage.getItem("userinfo"))).guard_name;
			config.headers["guardName"] = peripheryAdminUser;
		}
		if (sessionStorage.getItem("guardName")) {
			config.headers["guardName"] = sessionStorage.getItem("guardName");
		}
		if (sessionStorage.getItem("sharing")) {
			config.headers["shopId"] = sessionStorage.getItem("sharshopId");
		}
		return config;
	},
	err => {
		return Promise.reject(err);
	}
);
axios.interceptors.response.use(
	response => {
		//拦截响应，做统一处理 
		if (response.data.code == 402) {
			router.push({
				path: "/login"
			})

			resetRouter()
			this.$store.commit('routestatus', 0)
			sessionStorage.removeItem('routestext')
			sessionStorage.removeItem('routes')
			sessionStorage.removeItem('userinfo')
			sessionStorage.removeItem('token')
			sessionStorage.removeItem('isApply')
		} else if (response.data.code == 401) {
			if (sessionStorage.getItem('isApply')) {
				router.push({
					path: "/apply/login"
				})
			} else if (sessionStorage.getItem('userinfo')) {
				let userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
				let type = userinfo.type
				let guard_name = userinfo.guard_name
				let guard_info = {
					type: type,
				}
				sessionStorage.setItem('guard_info', JSON.stringify(guard_info))
				//判断登出到某个后台登录页面
				switch (guard_name) {
					default:
						guard_info.guard_name = 'api'
						guard_info.name = userinfo.shop_name
						if (type == 0) {
							router.replace({
								path: "/adminlogin"
							})
						} else {
							router.replace({
								path: "/login"
							})
						}
						break;
					case 'periphery_admin_user': //联联周边游分后台 type： 1、站点 2、商家
						guard_info.name = userinfo.user.shop_name
						guard_info.guard_name = 'periphery_admin_user'
						guard_info.shop_id = userinfo.user.shop_id
						router.replace({
							path: "/userlogin?name=" + userinfo.user.shop_name + "&type=" + type + "&shop_id=" + userinfo.shop_id + "&guard_name=periphery_admin_user"
						})
						break;
					case 'platform_agent_admin_user': //联联周边游分后台 type： 1、站点 2、商家
						guard_info.guard_name = 'platform_agent_admin_user'
						router.replace({
							path: "/agentlogin"
						})
						break;
				}
			} else {
				router.push({
					path: "/login"
				})
			}
			resetRouter()
			store.commit('routestatus', 0)
			sessionStorage.removeItem('routestext')
			sessionStorage.removeItem('routes')
			sessionStorage.removeItem('userinfo')
			sessionStorage.removeItem('token')
			sessionStorage.removeItem('isApply')
		} else if (response.data.code == 410) {
			router.push({
				path: "/payapps"
			})
		} else {
			return response.data
		}
	},
)
Vue.prototype.axios = axios

new Vue({
	router,
	store,
	axios,
	render: h => h(Layout)
}).$mount('#app')
